import Box from "corecomponents/Box";
import Heading from "corecomponents/Heading";
import Image from "corecomponents/Image";
import { graphql, useStaticQuery } from "gatsby";
import { Col, Container, Row } from "corecomponents/Layout";
import Text from "corecomponents/Text";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import BannerWrapper from "./banner.style";
import data from "data/useCaseCarousel";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

// install Swiper modules
SwiperCore.use([Pagination]);

const BannerTwo = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <BannerWrapper>
      <Container>
        <Row>
          <Col className="lg-7 md-12 xs-12">
            <Box className="banner-content">
              <Heading>
                CryptoGenome,
                <br />
                <Trans t={t}>our Web3 identity</Trans>
              </Heading>
              <Text as="span" className="description">
                <Trans t={t}>landing-page-short-description</Trans>
              </Text>
            </Box>
          </Col>

          <Col className="lg-5 md-12 xs-12 ">
            <Box className="use-case">
              <Swiper
                pagination={{
                  dynamicBullets: true
                }}
                grabCursor={true}
              >
                {data.map((item, index) => (
                  <SwiperSlide>
                    <Box className="use-case-carousel">
                      <Row>
                        <Col className="lg-12 md-12 xs-12 use-case-img">
                          <Image src={item.image} />
                        </Col>

                        <Col className="lg-12 md-12 xs-12 use-case-text">
                          <Text>{t(item.trans_tag)}</Text>
                        </Col>
                      </Row>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Col>
        </Row>
      </Container>
    </BannerWrapper>
  );
};

export default BannerTwo;
