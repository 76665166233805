import React from "react";

import { FaTwitter, FaDiscord, FaGithub } from "react-icons/fa";

export default [
  {
    name: "twitter",
    trans_tag: "twitter",
    url: "https://twitter.com/crypto_genome",
    react_icon: <FaTwitter />
  }
  // {
  //   name: "discord",
  //   trans_tag: "discord",
  //   url: "",
  //   react_icon: <FaDiscord />
  // }
  // {
  //   name: "github",
  //   trans_tag: "github",
  //   url: "",
  //   react_icon: <FaGithub />
  // }
];
