import Box from "corecomponents/Box";
import Heading from "corecomponents/Heading";
import { Col, Container, Row } from "corecomponents/Layout";
import { List, ListItem } from "corecomponents/List";
import Text from "corecomponents/Text";
import { Link } from "gatsby";
import React from "react";
import FooterWrapper from "./footer.style";
import data from "data/socialContacts";
import Image from "corecomponents/Image";
import logo from "assets/images/cg_logo.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <Box className="footer-content-wrapper">
        <Container>
          <Row>
            <Col className="lg-4 sm-12">
              <Box className="footer-widgets company-desc">
                <Heading as="h2"></Heading>

                <Box className="footer-social-links">
                  {data.map((item, index) => (
                    <Link to={item.url} key={index}>
                      {item.react_icon}
                    </Link>
                  ))}
                </Box>
              </Box>
            </Col>
          </Row>
          <Row className="bottom-text-box">
            <Col className="lg-10 xs-12"></Col>
            <Col className="lg-2 xs-12">
              <Box className="copyright-text">
                <Text>&copy; 2022 CryptoGenome Team </Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
