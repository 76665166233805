import React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

const ImageWrapper = styled.img`
  display: block;
`;

const Image = ({ src, alt, ...props }) => {
  const AllClasses = ["image"];
  if (props.className) {
    AllClasses.push(props.className);
  }
  if (props.isSvg) {
    return <SVG src={src} {...props} className={AllClasses.join(" ")} />;
  } else {
    return (
      <ImageWrapper
        src={src}
        alt={alt}
        {...props}
        className={AllClasses.join(" ")}
      />
    );
  }
};

export default Image;
