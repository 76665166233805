import React from "react";

import nft_image from "assets/images/use_cases/use_case_1.png";
import gaming_image from "assets/images/use_cases/use_case_2.png";
import influencer_image from "assets/images/use_cases/use_case_3.png";
import community_image from "assets/images/use_cases/use_case_4.png";
import more_image from "assets/images/use_cases/use_case_5.png";

export default [
  {
    name: "nft",
    trans_tag: "landing-page-usecase-nft",
    image: nft_image
  },
  {
    name: "gaming",
    trans_tag: "landing-page-usecase-gaming",
    image: gaming_image
  },
  {
    name: "influencer",
    trans_tag: "landing-page-usecase-influencer",
    image: influencer_image
  },
  {
    name: "community",
    trans_tag: "landing-page-usecase-community",
    image: community_image
  },
  {
    name: "more",
    trans_tag: "landing-page-usecase-more",
    image: more_image
  }
];
