import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

const Layout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{`CryptoGenome: ` + t("our Web3 identity")}</title>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>

      <main>{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
