import styled from "styled-components";

const FooterWrapper = styled.div`
  .footer-content-wrapper {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  a {
    &:hover {
      color: #fff;
    }
  }

  .footer-widgets {
    margin-bottom: 3px;

    a {
      color: #beabdf;
      font-size: 18px;
      font-weight: 300;
      line-height: 38px;
    }

    img {
      width: 180px;
      margin-bottom: 27px;
    }

    &.company-desc {
      .text {
        line-height: 16px;
        font-size: 14px;
        color: ${(props) => props.theme.footerDescriptionTextCoor};
      }

      svg {
        height: 25px;

        & rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon,
        path,
        text {
          fill: ${(props) => props.theme.logoFillColor};
        }
      }
    }

    &.address {
      font-size: 18px;
      p {
        line-height: 38px;
      }
    }

    .heading {
      font-size: 20px;
      font-weight: 500;
      color: ${(props) => props.theme.footerHeadingText};
      margin-bottom: 5px;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: ${(props) => props.theme.footerHeadingText};
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      a {
        display: flex;
        align-items: center;
        svg {
          margin-right: 15px;
        }
      }
    }
  }

  .footer-social-links {
    a {
      font-size: 15px;
      font-weight: 700;
      margin-right: 10px;
      color: ${(props) => props.theme.footerSocialIconColor};
      &:hover {
        color: ${(props) => props.theme.footerSocialIconHoverColor};
      }
    }
  }

  .bottom-text-box {
    border-top: 1px solid ${(props) => props.theme.footerWrapLineColor};

    .copyright-text {
      font-size: 12px;
      font-weight: 10;
      display: flex;
      justify-content: space-between;
      padding: 4px 0 25px 0px;
    }
  }
`;

export default FooterWrapper;
