import * as React from "react";
import { graphql, Link as GatsbyLink } from "gatsby";
import { ThemeProvider } from "styled-components";
import Footer from "components/Footer";
import Layout from "components/layout";
import Navigation from "components/Navigation";
import BannerTwo from "components/Banner";
import { buildTheme, GlobalStyle } from "data/theme";

const IndexPage = () => {
  const selectedTheme = buildTheme("light");

  return (
    <ThemeProvider theme={selectedTheme}>
      <GlobalStyle />
      <Layout>
        <Navigation />
        <BannerTwo />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "landingPage"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
