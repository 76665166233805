import { createGlobalStyle } from "styled-components";
import { mix } from "polished";
import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

/*******************************************************************************
 * Defines
 ******************************************************************************/

const black = "#000000";
const black35 = "#595959";
const black45 = "#737373";

const white = "#ffffff";
const white84 = "#d9d9d9";

const logoColor55 = "#642aed"; // main color
const logoColor65 = "#885bf1";
const logoColor85 = "#ccb9f9";
const logoColor90 = "#ddd0fb";

/*******************************************************************************
 * Theme colors
 ******************************************************************************/

const light = "light";
const dark = "dark";

const themeColors = {
  //colors:
  buttonPrimary: {
    light: "#AA00CC",
    dark: ""
  },
  bodyText: {
    light: "#333333",
    dark: "#ffffff"
  },
  selectionBackground: {
    light: logoColor55,
    dark: "#ffffff"
  },
  selectionColor: {
    light: "#ffffff",
    dark: "#333333"
  },
  backgroundColor: {
    light: white,
    dark: black
  },
  // MDX left side nav
  hoverColor: {
    light: logoColor90,
    dark: white
  },
  itemIndicatorColor: {
    light: black45,
    dark: white
  },
  itemIndicatorSelectedColor: {
    light: logoColor55,
    dark: white
  },
  // MDX right content nav
  selectedTextColor: {
    light: white,
    dark: black
  },
  selectedBackgroundColor: {
    light: logoColor65,
    dark: white
  },
  contentTextColor: {
    light: black,
    dark: white
  },
  // Nav bar
  navWrapLineColor: {
    light: "#cfc8ce",
    dark: white84
  },
  navDropdownBackground: {
    light: "#ffffff",
    dark: "#333333"
  },
  navItemTextColor: {
    light: black,
    darak: white
  },
  navScrollBottomBorderColor: {
    light: logoColor55,
    dark: white84
  },
  navScrollBottomShadowColorRGB: {
    light: "100 42 237",
    dark: white84
  },
  navScrollBackgroundColor: {
    light: white,
    dark: black35
  },
  logoScrollFillColor: {
    light: "#642aed",
    dark: white
  },
  // Banner section
  bannerBigTitleColor: {
    light: "#333333",
    dark: "#ffffff"
  },
  bannerDescriptionColor: {
    light: "#333333",
    dark: "#ffffff"
  },
  bannerIconColor: {
    light: "#333333",
    dark: "#ffffff"
  },
  logoFillColor: {
    light: "#642aed",
    dark: "#bba1f7"
  },
  logoFillColorSmallScreen: {
    light: "#642aed",
    dark: white
  },
  // Footer
  footerHeadingText: {
    light: "#666666",
    dark: "#ffffff"
  },
  footerWrapLineColor: {
    light: "#cfc8ce",
    dark: "#ffffff"
  },
  footerSocialIconColor: {
    light: logoColor85,
    dark: logoColor85
  },
  footerSocialIconHoverColor: {
    light: logoColor55,
    dark: logoColor55
  },
  footerDescriptionTextCoor: {
    light: "#666666",
    dark: "#ffffff"
  },
  // Generam Text component
  textColor: {
    light: "#333333",
    dark: "#ffffff"
  },
  anchorColor: {
    light: logoColor65,
    dark: logoColor65
  },
  // Content side menu
  sideMenuTextColor: {
    light: black35,
    dark: "#ffffff"
  },
  sideMenuSelectedBackground: {
    light: white,
    dark: "#ffffff"
  },
  sideMenuSelectedColor: {
    light: logoColor65,
    dark: "#ffffff"
  },
  // Mdx Top Nav
  mdxTopNavBackgroundColor: {
    light: white,
    dark: "#ffffff"
  }
};

/*******************************************************************************
 * MUI themes
 ******************************************************************************/
const MUITheme = createTheme({
  typography: {
    fontFamily: "Quicksand"
  }
});

/*******************************************************************************
 * Global style (home page)
 ******************************************************************************/

const GlobalStyle = createGlobalStyle`

  ::selection {
    background: ${(props) => props.theme.selectionBackground};
    color: ${(props) => props.theme.selectionColor};
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

  html{
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    height: 100%;
  }

  body,html {

    margin: 0px;

  }

  body {
    font-size: 16px;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', sans-serif;
    color: ${(props) => props.theme.bodyColor};
    overflow-x: hidden !important;
    font-weight: 400;
    margin: 0;
    padding: 0;

    background: ${(props) => props.theme.backgroundColor};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif;
    color: ${(props) => props.theme.headingColor};
  }

  p {
    font-family: sans-serif;
      margin: 0 0 15px 0;
  }

  a {
    stext-decoration: none;
    color: ${(props) => props.theme.anchorColor};
  }

  .text-center{
    text-align: center;
  }
`;

function buildTheme(themeChoice) {
  const colorTheme = {};
  for (const [key, value] of Object.entries(themeColors)) {
    colorTheme[key] = value[themeChoice];
  }

  return createTheme(deepmerge(colorTheme, MUITheme));
}

export { buildTheme, GlobalStyle };
